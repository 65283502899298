import { Product } from '@oolio-group/domain';
import { translate, useCurrency } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { useNotification } from '../../../hooks/Notification';
import { addToValue } from '../../../utils/keypadHelper';
import Button from '../../Button/Button';
import InputDisplay from '../../InputDisplay/InputDisplay';
import NumberInput from '../../NumberInput/NumberInput';
import Modal from '../Modal';
import TitleBar from '../../TitleBar/TitleBar';
import IconButton from '../../Button/IconButton';
import { ButtonProps } from '../../Button/Button';
import { isWeb } from '../../../common/theme';
import { MAX_KEYPAD_INPUT } from '../../../types/Common';
import theme from '../../../common/default-theme';

const CloseButton: React.FC<ButtonProps> = ({ onPress }) => (
  <IconButton
    icon="times"
    onPress={onPress}
    containerStyle={styles.closeButton}
  />
);

export interface VariablePriceModalProps {
  item?: Product;
  defaultPrice: number;
  onSubmit: (price: number) => void;
}

const styles = StyleSheet.create({
  container: {
    width: 360,
    alignSelf: 'center',
    paddingTop: 0,
    paddingVertical: 6,
  },
  closeButton: {
    position: 'absolute',
    left: 16,
  },
  modal: {
    paddingTop: 0,
  },
  cartItemContainer: {
    backgroundColor: theme.colors.grey1,
    height: 26,
    width: '100%',
    justifyContent: 'center',
    paddingLeft: 6,
    marginBottom: 10,
    borderRadius: 4,
  },
  cartItem: {
    color: theme.colors.black1,
    fontSize: 14,
    fontFamily: theme.fonts.medium,
  },
  inputDisplayContainer: {
    backgroundColor: theme.colors.teritiary1,
    borderRadius: 4,
  },
  fullWidth: {
    width: '100%',
  },
  keypad: {
    flex: 0.7,
  },
  inputDisplay: {
    height: 100,
  },
  priceRangeText: {
    fontSize: 14,
    color: theme.colors.white,
    fontFamily: theme.fonts.semibold,
    alignSelf: 'center',
    paddingBottom: 10,
    opacity: 0.6,
  },
  deleteChar: {
    padding: 12,
    transform: [{ scaleX: -1 }],
  },
  header: {
    width: 360,
    marginTop: 10,
    alignSelf: 'center',
  },
  keypadContainer: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 20 * (isWeb ? 1.25 : 0.75),
    alignSelf: 'center',
  },
  keyRow: {
    marginTop: '2%',
  },
  numberInput: {
    flexGrow: 1,
  },
});

export const VariablePriceModal: React.FC<VariablePriceModalProps> = ({
  item,
  onSubmit,
  defaultPrice,
}) => {
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const [isKeyPressed, setIsKeyPressed] = useState<boolean>(false);
  const { formatCurrency, decimalSeparator, unFormatCurrency } = useCurrency();

  const minSellingPrice = item?.minSellingPrice || 0;
  const maxSellingPrice = item?.maxSellingPrice || 0;

  const [input, setInput] = useState('');

  const numberKeys = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '0' },
    { value: decimalSeparator },
  ];

  const onPressKey = useCallback(
    (key: string): void => {
      setInput((preInput: string) => {
        const newValue = addToValue({
          value: preInput,
          key,
          decimalSeparator,
          length: MAX_KEYPAD_INPUT,
        });
        const isHigherThanMaximum = maxSellingPrice <= Number(newValue);
        if (isHigherThanMaximum) return maxSellingPrice.toString();
        return newValue;
      });
      if (!isKeyPressed) setIsKeyPressed(true);
    },
    [decimalSeparator, isKeyPressed, maxSellingPrice, setIsKeyPressed],
  );

  const handlePressDeleteInput = useCallback((): void => {
    setInput(prev => {
      return prev.slice(0, -1);
    });
    if (!isKeyPressed) setIsKeyPressed(true);
  }, [isKeyPressed, setIsKeyPressed]);

  const handleApplyChange = useCallback(() => {
    const isLowerThanMinimum = minSellingPrice > Number(input);
    if (isLowerThanMinimum && isKeyPressed) {
      setInput('0');
      showNotification({
        message: translate('variablePriceModal.minPriceError', {
          value: `${formatCurrency(minSellingPrice)}`,
        }),
        error: true,
      });
      return;
    }
    if (!!input) {
      onSubmit(Number(input));
    } else {
      onSubmit(Number(defaultPrice));
    }
  }, [
    defaultPrice,
    formatCurrency,
    input,
    isKeyPressed,
    minSellingPrice,
    onSubmit,
    showNotification,
  ]);

  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={styles.header}
        title={translate('variablePriceModal.title')}
        titleLeft={<CloseButton onPress={closeModal} />}
      />

      <Modal
        customBodyStyle={styles.modal}
        contentStyle={styles.container}
        showCloseButton={false}
      >
        <View style={styles.cartItemContainer}>
          <Text style={styles.cartItem}>{item?.name}</Text>
        </View>
        <View style={styles.inputDisplayContainer}>
          <InputDisplay
            testID="price-display"
            value={
              isKeyPressed
                ? formatCurrency(unFormatCurrency(input))
                : formatCurrency(Number(defaultPrice))
            }
            right={
              <IconButton
                testID="clearButton"
                circular
                icon="cancel"
                containerStyle={styles.deleteChar}
                onPress={handlePressDeleteInput}
                iconSize={24}
              />
            }
            containerStyle={styles.inputDisplay}
          />
          <Text style={styles.priceRangeText}>
            {`${formatCurrency(minSellingPrice)} - ${formatCurrency(
              maxSellingPrice,
            )}`}
          </Text>
        </View>
        <View style={[styles.keypadContainer, styles.fullWidth]}>
          <NumberInput
            keyWidth={100}
            keyHeight={74}
            twistKeyWidth={2.05}
            onPressKey={onPressKey}
            rowStyle={styles.keyRow}
            containerStyle={styles.numberInput}
            keys={numberKeys}
          />
        </View>
        <Button
          fluid
          success
          testID="submit-button"
          onPress={handleApplyChange}
          title={translate('button.applyChanges')}
        />
      </Modal>
    </View>
  );
};
