import React, { FC } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
  StyleSheet,
} from 'react-native';
import theme from '../../../../../common/default-theme';

export interface NumberPadProps {
  keypadButtons: { value: string }[];
  zeroButtonWidth: number;
  onPressKey: (key: string) => void;
}

const styles = StyleSheet.create({
  keypad: {
    gap: 4,
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 20,
  },
  btnKeypad: {
    height: 70,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  textKeypad: {
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.semibold,
    fontVariant: ['tabular-nums'],
  },
});

const NumberPad: FC<NumberPadProps> = ({
  keypadButtons,
  zeroButtonWidth,
  onPressKey,
}) => {
  return (
    <View style={styles.keypad}>
      {keypadButtons.map(({ value }) => (
        <TouchableOpacity
          key={`key-${value}`}
          testID={`key-${value}`}
          onPress={() => onPressKey(value || '0')}
          style={[
            styles.btnKeypad,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              width: value === '0' ? zeroButtonWidth : 90.33,
            } as ViewStyle,
          ]}
        >
          <Text style={styles.textKeypad}>{value}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default NumberPad;
