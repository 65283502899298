import { Adjustment, AdjustmentType, OrderItem } from '@oolio-group/domain';
import { translate, useCurrency } from '@oolio-group/localization';
import { computeLineTotal } from '@oolio-group/order-helper';
import { getAdjustmentValue } from '@oolio-group/order-helper';

import { useModal } from '@oolio-group/rn-use-modal';
import {
  StyleFn,
  RenderProps,
  AdjustmentUnit,
  CUSTOM_ADJUSTMENT_ID,
} from '@oolio-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { StyleSheet, Text, View } from 'react-native';
import { useNotification } from '../../../hooks/Notification';
import { addToValue } from '../../../utils/keypadHelper';
import Button from '../../Button/Button';
import CartProduct from '../../POS/Cart/CartProduct/CartProduct';
import InputDisplay from '../../InputDisplay/InputDisplay';
import NumberInput, { NumberKey } from '../../NumberInput/NumberInput';
import Modal from '../Modal';
import scale, { isAndroid, isWeb } from '../../../common/theme';
import TitleBar from '../../TitleBar/TitleBar';
import { ButtonProps } from '../../Button/Button';
import { FelaComponent } from 'react-fela';
import IconButton from '../../Button/IconButton';
import Title from '../../Title/Title';
import { MAX_KEYPAD_INPUT } from '../../../types/Common';

export interface SetPriceModalProps {
  item: OrderItem;
  title?: string;
  defaultPrice: number;
  maxDiscounts?: number;
  minSellingPrice?: number;
  maxSellingPrice?: number;
  priceOverrideMode?: 'default' | 'discount';
  onSubmit: (price: number, adjustments: Adjustment[]) => void;
  keypadInput?: string;
}

const discountStyle: StyleFn = ({ theme }) => ({
  height: 24,
  minWidth: 75,
  color: theme.colors.white,
  fontSize: theme.fontSize.small,
  lineHeight: 24,
  borderRadius: theme.radius.small,
  fontFamily: theme.font.semibold,
  textAlign: 'center',
  letterSpacing: -0.5,
  backgroundColor: theme.colors.primaryDark,
});

const keypadStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.medium * 1.25,
});

const keyRowStyle: StyleFn = ({}) => ({
  marginTop: '4%',
});

const numberInputStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing.medium * 1.25,
});

const containerStyle: StyleFn = ({ item }) => ({
  width: 445,
  minHeight: isWeb ? 540 : 570,
  alignSelf: 'center',
  height: item && item.product ? (isWeb ? 580 : 610) : isWeb ? 540 : 570,
  paddingVertical: 0,
});
const extraTextStyle: StyleFn = () => ({
  right: scale.moderateScale(15),
});
const extraRightFlank: StyleFn = () => ({
  justifyContent: 'center',
  right: 10,
});
const btnContainer: StyleFn = () => ({
  bottom: isWeb
    ? 0
    : isAndroid
    ? scale.moderateScale(5)
    : scale.moderateScale(2),
});
const headerStyle: StyleFn = ({ theme }) => ({
  width: 445,
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  cartItemFullWidth: {
    width: '100%',
    bottom: 10,
  },
  keypad: {
    flex: 0.7,
  },
});
const btnTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium + 1,
});
export function adjustDiscounts(
  adjustments: Adjustment[],
  maxDiscounts = Infinity,
) {
  return adjustments.slice(-maxDiscounts);
}

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const inputDisplayStyle: StyleFn = () => ({
  height: 100,
});

const modifiersLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  lineHeight: 21,
  textTransform: 'uppercase',
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
});

const modifiersLabelContainerStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.small,
  marginLeft: 4 * theme.spacing.small,
});

const CloseButtons: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

export const SetPriceModal: React.FC<SetPriceModalProps> = ({
  item,
  title,
  onSubmit,
  maxDiscounts,
  defaultPrice,
  minSellingPrice,
  maxSellingPrice,
  priceOverrideMode,
  keypadInput,
}) => {
  const { css } = useFela({ item });
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const {
    formatCurrency,
    currencySymbol,
    decimalSeparator,
    unFormatCurrency,
    formatMoneyValue,
  } = useCurrency();
  const maxPercentage = 100;
  const [input, setInput] = useState<string>(keypadInput || '');
  const [unitPrice, adjustUnitPrice] = useState(item.unitPrice);
  const [adjustments, addAdjustment] = useState<Adjustment[]>(
    item.adjustments || [],
  );

  const modifiersText =
    item.modifiers?.length > 1
      ? `${item.modifiers[0].name} + ${item.modifiers.length - 1} ${translate(
          'modifiers.modifiers',
        )}`
      : item.modifiers?.length === 1
      ? `${item.modifiers[0].name}`
      : '';

  const originalTotal = computeLineTotal({
    ...item,
    unitPrice: defaultPrice,
    discounts: [],
  });
  const totalBeforeDiscount = computeLineTotal({
    ...item,
    unitPrice,
    discounts: [],
  });
  const totalAfterDiscount =
    totalBeforeDiscount + getAdjustmentValue(totalBeforeDiscount, adjustments);

  const onPressKey = useCallback(
    (key: string): void => {
      setInput(value =>
        addToValue({ value, key, decimalSeparator, length: MAX_KEYPAD_INPUT }),
      );
    },
    [decimalSeparator],
  );

  const onPressPercentOff = useCallback(() => {
    setInput(input => {
      if (unFormatCurrency(input) > maxPercentage) {
        showNotification({
          message: translate('order.maxDiscountWarning', { value: '100%' }),
          error: true,
        });
        return '';
      }

      addAdjustment(adjustment =>
        adjustDiscounts(
          [
            ...adjustment,
            {
              id: CUSTOM_ADJUSTMENT_ID,
              amount: -unFormatCurrency(input),
              adjustmentUnit: AdjustmentUnit.PERCENTAGE,
              allowOnPaymentType: false,
              adjustmentType: AdjustmentType.DISCOUNT,
            },
          ],
          maxDiscounts,
        ),
      );
      return '';
    });
  }, [maxDiscounts, showNotification, unFormatCurrency]);

  const onPressDollarOff = useCallback(() => {
    setInput(input => {
      if (unFormatCurrency(input) > originalTotal) {
        showNotification({
          message: translate('order.maxDiscountWarning', {
            value: `${formatCurrency(originalTotal)}`,
          }),
          error: true,
        });
        return '';
      }

      addAdjustment(adjustment =>
        adjustDiscounts(
          [
            ...adjustment,
            {
              id: CUSTOM_ADJUSTMENT_ID,
              amount: -unFormatCurrency(input),
              adjustmentUnit: AdjustmentUnit.FLAT,
              allowOnPaymentType: false,
              adjustmentType: AdjustmentType.DISCOUNT,
            },
          ],
          maxDiscounts,
        ),
      );
      return '';
    });
  }, [
    maxDiscounts,
    originalTotal,
    showNotification,
    formatCurrency,
    unFormatCurrency,
  ]);

  const onPressAdjust = useCallback(() => {
    setInput(input => {
      const price = unFormatCurrency(input);

      if (!price) {
        return input;
      }

      if (minSellingPrice && price < minSellingPrice) {
        showNotification({
          message: translate('order.minPriceWarning', {
            price: formatCurrency(minSellingPrice),
          }),
          error: true,
        });
      } else if (maxSellingPrice && price > maxSellingPrice) {
        showNotification({
          message: translate('order.maxPriceWarning', {
            price: formatCurrency(maxSellingPrice),
          }),
          error: true,
        });
      } else {
        if (priceOverrideMode === 'default') {
          adjustUnitPrice(price);
        } else {
          addAdjustment(
            adjustDiscounts(
              [
                {
                  id: CUSTOM_ADJUSTMENT_ID,
                  amount: price - unitPrice,
                  adjustmentUnit: AdjustmentUnit.FLAT,
                  allowOnPaymentType: false,
                  adjustmentType:
                    price - unitPrice > 0
                      ? AdjustmentType.SURCHARGE
                      : AdjustmentType.DISCOUNT,
                },
              ],
              maxDiscounts,
            ),
          );
        }
      }

      return '';
    });
  }, [
    minSellingPrice,
    maxSellingPrice,
    showNotification,
    formatCurrency,
    priceOverrideMode,
    unitPrice,
    maxDiscounts,
    unFormatCurrency,
  ]);

  const onPressRevert = useCallback(() => {
    setInput('');
    addAdjustment([]);
    adjustUnitPrice(defaultPrice);
  }, [defaultPrice]);

  const onPressSubmit = useCallback(() => {
    closeModal();
    onSubmit(unitPrice, adjustments);
  }, [onSubmit, adjustments, unitPrice, closeModal]);

  const priceVariation = totalAfterDiscount / originalTotal;
  const variationPercent = Math.abs((1 - priceVariation) * 100);
  const variationFormatted = `${
    totalAfterDiscount < originalTotal ? '-' : '+'
  }${variationPercent.toFixed(2)}%`;
  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={css(headerStyle)}
        title={title || translate('order.setItemPrice')}
        titleLeft={<CloseButtons onPress={closeModal} />}
      />
      <Modal contentStyle={css(containerStyle)} showCloseButton={false}>
        <View style={styles.cartItemFullWidth}>
          {item && item.product && (
            <CartProduct minimised testID="product" orderItem={item} />
          )}
        </View>
        {modifiersText !== '' && (
          <Title
            numberOfLines={1}
            labelStyle={css(modifiersLabelStyle)}
            alignTitle={'left'}
            containerStyle={css(modifiersLabelContainerStyle)}
          >
            {modifiersText}
          </Title>
        )}
        <InputDisplay
          value={formatMoneyValue(
            unFormatCurrency(input) || totalAfterDiscount,
          )}
          right={
            <Text testID="discount-display" style={css(discountStyle)}>
              {variationFormatted}
            </Text>
          }
          extraTextStyle={css(extraTextStyle)}
          extraRightFlank={css(extraRightFlank)}
          containerStyle={css(inputDisplayStyle)}
        />
        <View style={[css(keypadStyle), styles.fullWidth]}>
          <NumberInput
            onPressKey={onPressKey}
            containerStyle={css(numberInputStyle)}
            keys={[
              { value: '1' },
              { value: '2' },
              { value: '3' },
              { value: '4' },
              { value: '5' },
              { value: '6' },
              { value: '7' },
              { value: '8' },
              { value: '9' },
              { value: '0' },
              { value: decimalSeparator },
            ]}
            keyWidth={80}
            keyHeight={70}
            twistKeyWidth={2.05}
          />
          <View>
            <View style={css(keyRowStyle)}>
              <NumberKey
                width={120}
                height={70}
                testID="percent-off-button"
                onPressKey={onPressPercentOff}
                value={translate('button.percentOff')}
                labelStyle={css(btnTextStyle)}
              />
            </View>
            <View style={css(keyRowStyle)}>
              <NumberKey
                width={120}
                height={70}
                testID="dollar-off-button"
                onPressKey={onPressDollarOff}
                value={translate('button.dollarOff', {
                  currency: currencySymbol,
                })}
                labelStyle={css(btnTextStyle)}
              />
            </View>
            <View style={css(keyRowStyle)}>
              <NumberKey
                width={120}
                height={70}
                testID="adjust-button"
                onPressKey={onPressAdjust}
                value={translate('button.adjust')}
                labelStyle={css(btnTextStyle)}
              />
            </View>
            <View style={css(keyRowStyle)}>
              <NumberKey
                width={120}
                height={70}
                testID="revert-button"
                onPressKey={onPressRevert}
                value={translate('button.revert')}
                labelStyle={css(btnTextStyle)}
              />
            </View>
          </View>
        </View>
        <Button
          fluid
          success
          testID="submit-button"
          onPress={onPressSubmit}
          title={translate('button.applyChanges')}
          containerStyle={css(btnContainer)}
        />
      </Modal>
    </View>
  );
};

SetPriceModal.defaultProps = {
  priceOverrideMode: 'default',
};
