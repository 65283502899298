import React, { useMemo, useState, useCallback } from 'react';
import { View, Text } from 'react-native';
import { Booking } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import { styles } from './BookingsTable.styles';
import BookingRow from './BookingRow';
import Empty from '../../../../components/Shared/Empty/Empty';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import { useFocusEffect } from '@react-navigation/native';
import { FlatList } from 'react-native-gesture-handler';

interface Props {
  status: string;
  bookings: Booking[];
  currentPage: number;
  onPageChange: (page: number) => void;
  onSelectBooking: (entry: Booking) => void;
}

const PAGE_SIZE = 50;

const BookingsTable: React.FC<Props> = ({
  status,
  bookings = [],
  currentPage,
  onPageChange,
  onSelectBooking,
}) => {
  const { translate } = useTranslation();

  const [currentTime, setCurrentTime] = useState(Date.now());

  useFocusEffect(
    useCallback(() => {
      const interval = setInterval(() => {
        setCurrentTime(Date.now());
      }, 60000);
      return () => clearInterval(interval);
    }, []),
  );

  const isWaitlist = status === 'WAITLIST';

  const paginatedBookings = useMemo(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    return bookings.slice(startIndex, startIndex + PAGE_SIZE);
  }, [bookings, currentPage]);

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.header,
          styles.header,
          // eslint-disable-next-line react-native/no-inline-styles
          { paddingLeft: isWaitlist ? 72 : 72 },
        ]}
      >
        <Text style={[styles.cellGuestCount, theme.tables.headerText]}>
          {translate('bookings.queued')}
        </Text>
        <Text style={[styles.cellGuestCount, theme.tables.headerText]}>
          {translate('form.guests')}
        </Text>
        <Text style={[styles.cellName, theme.tables.headerText]}>
          {translate('button.customer')}
        </Text>
        <Text style={[styles.cellPhone, theme.tables.headerText]}>
          {translate('form.phone')}
        </Text>
        <Text style={[styles.cellCreated, theme.tables.headerText]}>
          {translate('salesDashboard.CreatedAt')}
        </Text>
      </View>
      <FlatList
        data={paginatedBookings}
        contentContainerStyle={styles.body}
        renderItem={({ item, index }) => (
          <BookingRow
            key={`row-${index}`}
            filter={status}
            index={(currentPage - 1) * PAGE_SIZE + index + 1}
            booking={item}
            onPress={() => onSelectBooking(item)}
            currentTime={currentTime}
          />
        )}
        ListEmptyComponent={() => (
          <Empty label={translate('bookings.emptyBookings')} />
        )}
      />
      {bookings?.length > 10 && (
        <Pagination
          page={currentPage}
          dataLength={bookings.length}
          pageLength={PAGE_SIZE}
          onPageChange={onPageChange}
        />
      )}
    </View>
  );
};

export default BookingsTable;
