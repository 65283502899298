import { Product } from '@oolio-group/domain';
import { translate } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import React, { useCallback, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { addToValue } from '../../../utils/keypadHelper';
import Button from '../../Button/Button';
import InputDisplay from '../../InputDisplay/InputDisplay';
import NumberInput from '../../NumberInput/NumberInput';
import Modal from '../Modal';
import TitleBar from '../../TitleBar/TitleBar';
import IconButton from '../../Button/IconButton';
import { ButtonProps } from '../../Button/Button';
import { isWeb } from '../../../common/theme';
import { MAX_KEYPAD_INPUT } from '../../../types/Common';
import theme from '../../../common/default-theme';
import { useCurrency } from '@oolio-group/localization';

const CloseButton: React.FC<ButtonProps> = ({ onPress }) => (
  <IconButton
    icon="times"
    onPress={onPress}
    containerStyle={styles.closeButton}
  />
);

export interface VariableQuantityModalProps {
  item?: Product;
  onSubmit: (qty: number) => void;
  unitPrice?: number;
  quantity?: number;
}

const styles = StyleSheet.create({
  container: {
    width: 360,
    alignSelf: 'center',
    paddingTop: 0,
    paddingVertical: 6,
  },
  closeButton: {
    position: 'absolute',
    left: 16,
  },
  modal: {
    paddingTop: 0,
  },
  cartItemContainer: {
    backgroundColor: theme.colors.grey1,
    height: 26,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 6,
    marginBottom: 5,
    borderRadius: 4,
  },
  volumeTextContainer: {
    justifyContent: 'center',
    marginLeft: 26,
    marginBottom: 10,
  },
  cartItem: {
    color: theme.colors.black1,
    fontSize: 14,
    fontFamily: theme.fonts.medium,
  },
  inputDisplayContainer: {
    backgroundColor: theme.colors.teritiary1,
    borderRadius: 4,
  },
  fullWidth: {
    width: '100%',
  },
  keypad: {
    flex: 0.7,
  },
  inputDisplay: {
    height: 100,
  },
  deleteChar: {
    padding: 12,
    transform: [{ scaleX: -1 }],
  },
  header: {
    width: 360,
    marginTop: 10,
    alignSelf: 'center',
  },
  keypadContainer: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 20 * (isWeb ? 1.25 : 0.75),
    alignSelf: 'center',
  },
  keyRow: {
    marginTop: '2%',
  },
  numberInput: {
    flexGrow: 1,
  },
});

export const VariableQuantityModal: React.FC<VariableQuantityModalProps> = ({
  item,
  onSubmit,
  unitPrice = 0,
  quantity,
}) => {
  const { closeModal } = useModal();
  const { formatCurrency } = useCurrency();

  const [input, setInput] = useState('');

  const numberKeys = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '0' },
    { value: '.' },
  ];

  const measuredBy = item?.measuredBy;
  const defaultSize = measuredBy?.defaultSize || 1;

  const onPressKey = useCallback((key: string): void => {
    setInput((preInput: string) => {
      const newValue = addToValue({
        value: preInput,
        key,
        decimalSeparator: '.',
        length: MAX_KEYPAD_INPUT,
        decimalPlaces: 3,
      });

      return newValue;
    });
  }, []);

  const handlePressDeleteInput = useCallback((): void => {
    setInput(prev => {
      return prev.slice(0, -1);
    });
  }, []);

  const updatedInput = !!input
    ? Number(input)
    : !!quantity
    ? quantity * defaultSize
    : Number(defaultSize);

  const handleApplyChange = useCallback(() => {
    onSubmit(updatedInput);

    closeModal();
  }, [closeModal, onSubmit, updatedInput]);

  const updatedQty = !!input
    ? Number(input) / defaultSize
    : !!quantity
    ? quantity
    : 1;
  const quantityPlaceHoler = '%';
  const productSize = parseFloat((updatedQty * defaultSize).toFixed(3));
  const sizeInfoText = `${productSize}${
    measuredBy?.units
  } NET @ ${formatCurrency(unitPrice || 0)}/${defaultSize}${measuredBy?.units}`;

  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={styles.header}
        title={translate('order.setQuantity')}
        titleLeft={<CloseButton onPress={closeModal} />}
      />

      <Modal
        customBodyStyle={styles.modal}
        contentStyle={styles.container}
        showCloseButton={false}
      >
        <View style={styles.cartItemContainer}>
          <Text style={styles.cartItem}>
            <Text style={styles.cartItem}>{quantityPlaceHoler + ' '} </Text>
            {item?.name}
          </Text>
          <Text style={styles.cartItem}>
            {formatCurrency(unitPrice * (updatedInput / defaultSize))}
          </Text>
        </View>
        <View style={styles.volumeTextContainer}>
          <Text style={styles.cartItem}>{sizeInfoText}</Text>
        </View>
        <InputDisplay
          testID="qty-display"
          value={`${updatedInput} ${item?.measuredBy.units}`}
          right={
            <IconButton
              testID="clearButton"
              circular
              icon="cancel"
              containerStyle={styles.deleteChar}
              onPress={handlePressDeleteInput}
              iconSize={24}
            />
          }
          containerStyle={styles.inputDisplay}
        />

        <View style={[styles.keypadContainer, styles.fullWidth]}>
          <NumberInput
            keyWidth={100}
            keyHeight={74}
            twistKeyWidth={2.05}
            onPressKey={onPressKey}
            rowStyle={styles.keyRow}
            containerStyle={styles.numberInput}
            keys={numberKeys}
          />
        </View>
        <Button
          fluid
          success
          testID="submit-button"
          onPress={handleApplyChange}
          title={translate('button.applyChanges')}
        />
      </Modal>
    </View>
  );
};
