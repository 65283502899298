import { RenderProps, StyleFn } from '@oolio-group/domain';
import { translate, useCurrency } from '@oolio-group/localization';
import React, { useCallback, useState } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { StyleSheet, View, Text } from 'react-native';
import Button, { ButtonProps } from '../../Button/Button';
import scale, { isWeb, isAndroid } from '../../../common/theme';
import IconButton from '../../Button/IconButton';
import InputDisplay from '../../InputDisplay/InputDisplay';
import NumberInput from '../../NumberInput/NumberInput';
import TitleBar from '../../TitleBar/TitleBar';
import Modal from '../Modal';
import { addToValue } from '../../../utils/keypadHelper';
import { MAX_KEYPAD_INPUT } from '../../../types/Common';

const keypadStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.medium * 1.25,
});

const numberInputStyle: StyleFn = () => ({
  flex: 1,
});

const containerStyle: StyleFn = () => ({
  width: 320,
  height: 520,
  alignSelf: 'center',
  paddingVertical: 0,
  paddingHorizontal: 20,
});
const extraRightFlank: StyleFn = () => ({
  justifyContent: 'center',
  right: 10,
});
const headerStyle: StyleFn = () => ({
  width: 320,
  alignSelf: 'center',
  textTransform: 'uppercase',
});

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  cartItemFullWidth: {
    width: '100%',
    bottom: 10,
  },
  keypad: {
    flex: 0.7,
  },
});

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const inputDisplayStyle: StyleFn = () => ({
  height: 90,
});

const backspaceButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.transparent,
});

const subText: StyleFn = () => ({
  color: 'rgba(255, 255, 255, 0.3)',
  marginTop: -22,
  zIndex: 1,
  alignSelf: 'center',
});

const btnContainer: StyleFn = () => ({
  bottom: isWeb
    ? 0
    : isAndroid
    ? scale.moderateScale(5)
    : scale.moderateScale(2),
});

const CloseButtons: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

interface SetAmountKeypadProps {
  closeModal: () => void;
  onSubmit: (price: number) => void;
  remainingDueAmount: number;
  processingAmount: number;
  step?: number;
}

const SetAmountKeypad: React.FC<SetAmountKeypadProps> = ({
  closeModal,
  onSubmit,
  remainingDueAmount,
  processingAmount,
  step,
}) => {
  const { css, theme } = useFela();
  const { formatCurrency, decimalSeparator, unFormatCurrency } = useCurrency();
  const [paymentAmountStr, setPaymentAmountStr] = useState<string>(
    `${processingAmount}`,
  );

  const onPressKeyNumber = useCallback(
    (key: string) => {
      setPaymentAmountStr(currentPaymentCount => {
        return currentPaymentCount === '0'
          ? key
          : addToValue({
              value: currentPaymentCount,
              key,
              decimalSeparator,
              length: MAX_KEYPAD_INPUT,
            });
      });
    },
    [decimalSeparator],
  );
  const onPressBackspace = useCallback(() => {
    setPaymentAmountStr(currentPaymentAmount =>
      currentPaymentAmount === '0' || currentPaymentAmount.length === 1
        ? '0'
        : currentPaymentAmount.slice(0, currentPaymentAmount.length - 1),
    );
  }, []);

  const onPressSubmit = useCallback(() => {
    onSubmit(unFormatCurrency(paymentAmountStr));
  }, [onSubmit, paymentAmountStr, unFormatCurrency]);

  const amountFloat = isNaN(Number(paymentAmountStr))
    ? 0
    : Number(paymentAmountStr);

  const numberKeys = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '0' },
    { value: decimalSeparator },
  ];

  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={css(headerStyle)}
        title={
          step
            ? translate('payment.setSplitPaymentStep', { step })
            : translate('payment.setPaymentAmount')
        }
        titleLeft={<CloseButtons onPress={closeModal} />}
      />
      <Modal contentStyle={css(containerStyle)} showCloseButton={false}>
        <InputDisplay
          value={formatCurrency(unFormatCurrency(paymentAmountStr))}
          right={
            <IconButton
              icon="backspace"
              iconSize={22}
              iconColor={theme.colors.white}
              containerSize={44}
              onPress={onPressBackspace}
              containerStyle={css(backspaceButtonStyle)}
            />
          }
          extraRightFlank={css(extraRightFlank)}
          containerStyle={css(inputDisplayStyle)}
        />
        <Text style={css(subText)} testID="remaining-value">
          {translate('payment.remainingDueAmount', {
            amount: formatCurrency(remainingDueAmount - amountFloat),
          })}
        </Text>
        <View style={[css(keypadStyle), styles.fullWidth]}>
          <NumberInput
            onPressKey={onPressKeyNumber}
            containerStyle={css(numberInputStyle)}
            keyHeight={70}
            keyWidth={91}
            twistKeyWidth={2.05}
            keys={numberKeys}
          />
        </View>
        <Button
          fluid
          success
          testID="submit-button"
          onPress={onPressSubmit}
          title={translate('payment.setAmount')}
          containerStyle={css(btnContainer)}
        />
      </Modal>
    </View>
  );
};

export default SetAmountKeypad;
