import React, { FC } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';
import ButtonIcon from '../../../../../components/Shared/TreatButton/ButtonIcon';

export interface KeypadDisplayProps {
  displayValue: string;
  onPressClear: () => void;
}

const styles = StyleSheet.create({
  input: {
    height: 80,
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.teritiary1,
  },
  inputText: {
    fontSize: 28,
    lineHeight: 32,
    textAlign: 'center',
    fontFamily: theme.fonts.semibold,
    color: theme.colors.states.focus,
    fontVariant: ['tabular-nums'],
  },
  btnClear: {
    position: 'absolute',
    right: 20,
  },
});

const KeypadDisplay: FC<KeypadDisplayProps> = ({
  displayValue,
  onPressClear,
}) => {
  return (
    <View style={styles.input}>
      <Text testID="input-value" style={styles.inputText}>
        {displayValue}
      </Text>
      <ButtonIcon
        testID="btn-clear"
        icon="times-circle"
        type="interface"
        transparent
        onPress={onPressClear}
        containerStyle={styles.btnClear}
      />
    </View>
  );
};

export default KeypadDisplay;
